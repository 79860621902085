export const customer = [
    {
        img: 'amag.png',
        name: 'AMAG',
        link: 'https://www.amag.ch'
    },
    {
        img: 'lgt.png',
        name: 'LGT Group',
        link: 'https://www.lgt.com',
    },
    {
        img: 'raiffeisen.png',
        name: 'Raiffeisen',
        link: 'https://www.raiffeisen.ch',
    },
    {
        img: 'ringier.png',
        name: 'Ringier Print AG',
        link: 'https://www.ringier.ch',
    },
    {
        img: 'swica.png',
        name: 'SWICA',
        link: 'https://www.swica.ch/'
    },
    {
        img: 'apg.png',
        name: 'APG|SGA AG',
        link: 'http://www.apgsga.ch/'
    },
    {
        img: 'Partnersgroup.png',
        name: 'Partners Group',
        link: 'http://www.partnersgroup.ch/'
    },
    {
        img: 'abraxas.png',
        name: 'Abraxas',
        link: 'https://www.abraxas.ch/'
    },
    {
        img: 'debrunner.png',
        name: 'Debrunner Acifer AG',
        link: 'https://www.dkh.ch/'
    },
    {
        img: 'zgkb.png',
        name: 'Zuger Kantonalbank',
        link: 'https://www.zugerkb.ch',
    },
    {
        img: 'zkb.png',
        name: 'Zürcher Kantonalbank',
        link: 'https://www.zkb.ch/',
    },
    {
        img: 'cler.png',
        name: 'Bank Cler',
        link: 'https://www.cler.ch/de',
    },
    {
        img: 'zhaw.png',
        name: 'ZHAW',
        link: 'https://www.zhaw.ch/',
    },
    {
        img: 'bakb.png',
        name: 'Basler Kantonalbank',
        link: 'https://www.bkb.ch/',
    },
    {
        img: 'wwf.png',
        name: 'WWF',
        link: 'https://www.wwf.ch/de',
    },
    {
        img: 'gdels.jpg',
        name: 'GDELS',
        link: 'https://www.gdels.com/de_index.php',
    },
    {
        img: 'bt.png',
        name: 'Bechtle AG',
        link: 'https://www.bechtle.com/ch',
    },
    {
        img: 'hs.png',
        name: 'HUBER+SUHNER',
        link: 'https://www.hubersuhner.com/de',
    },
    {
        img: 'hh.png',
        name: 'Abacus Research AG',
        link: 'https://www.abacus.ch/',
    },
]