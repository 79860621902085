import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap';

import { customer } from '../conf/customer'

class Customers extends React.Component {

  state = {
    sortedCustomers: [],
  }

  renderRefrence = (imgName, name, link, img, index) => (
    <Col className="reference" xs="6" sm="4" md="3" lg="2" key={"div_" + index}>
      <div className="reference-container">
        <a href={link} rel="noopener noreferrer" target="_blank">
          <Img
            title={name}
            key={"reference" + index}
            alt={imgName}
            fixed={img}
          />
        </a>
        <a href={link} rel="noopener noreferrer" target="_blank" className="name">{name}</a>
      </div>
    </Col>
  )

  componentDidMount = () => {
    this.setState({
      sortedCustomers: customer.sort(() => {
        return .5 - Math.random();
      })
    })
  }

  render() {
    return (
      <Layout>
        <section id="customer" className="section-wrapper">
          <header className="section-header">
            <h3>Kunden</h3>
          </header>
          <Row>
            {
              this.state.sortedCustomers.map(c => {
                return this.props.data.customer.edges.map((node, index) => {
                  if (node.node.childImageSharp.fixed.originalName === c.img) {
                    return this.renderRefrence(c.img, c.name, c.link, node.node.childImageSharp.fixed, index);
                  }
                  return null
                })
              })
            }
          </Row>
        </section>
      </Layout >

    )
  }
}

export const query = graphql`
{
  customer: allFile(filter: {sourceInstanceName: {eq: "customers"}}) {
    edges {
      node {
        childImageSharp {
          fixed(width: 140, height: 140) {
            ...GatsbyImageSharpFixed,
            originalName
          }
        }
      }
    }
  }
}
`

export default Customers
